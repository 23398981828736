import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';

import modules from './modules/index.js';
Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({ storage: window.localStorage });

const store = new Vuex.Store({
  state: {
    isOpen: 0,
    snack: {},
  },
  mutations: {
    open: function(state) {
      state.isOpen = true;
    },
    close: function(state) {
      state.isOpen = false;
    },
    showSnack: function(state, snack) {
      state.snack = snack;
    },
  },
  // plugins: [vuexLocal.plugin],
  modules,
});

// Automatically run the `init` action for every module,
// if one exists.
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
