import router from '@router/index.js';
import store from '@state/store.js';
import { format, parseISO } from 'date-fns';
import Vue from 'vue';
import App from './app.js';
import vueerr from './components/error.js';
import { footer, vVisible } from './components/plugins.js';
import loader from './loader.js';
import registerSW from './register.js';
import roles from './roles.js';
import VueMaterialAdapter from './vma.js';

export default async function main() {
  Vue.filter('isoDate', value => {
    return value ? format(parseISO(value), 'yyyy-MM-dd HH:mm') : 'n/a';
  });
  Vue.use(vueerr);
  Vue.use(VueMaterialAdapter);
  Vue.component('navbar', () => loader.load('components/navbar.js'));
  Vue.use(vVisible);
  Vue.component('site-footer', footer);

  Vue.component('lazy-image', () => loader.load('components/lazy-image.js'));
  Vue.component('lazy-media', () => loader.load('components/lazy-media.js'));

  store.dispatch('rbac/perms', { roles });
  return new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

// developers: the next line registers the service worker
// it can be commented out for debug or otherwise if necessary
// but remember to purge any service workers already installed
// from the browsers developer tools options.

// registerSW(store);
main();
