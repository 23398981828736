export default {
  participant: { can: [] },
  guide: { can: [] },
  manager: {
    can: ['auctions:edit'],
    inherits: ['guide'],
  },
  admin: {
    can: ['globalusers:admin', 'users:edit'],
    inherits: ['manager'],
  },
};
