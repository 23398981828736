import { http, uniqueId } from 'onc';

import api from '@state/api.js';

function defaultAuction_(_id) {
  return {
    _id,
    description: '',
    descriptionHtml: '',
    status: 'construction',
    modifiedDate: new Date(),
    name: 'n/a',
    items: [],
  };
}

export default {
  namespaced: true,
  state: {
    currentAuction: false,
  },
  mutations: {
    SET_CURRENT_AUCTION(state, newValue) {
      state.currentAuction = newValue;
    },
    SET_DESCRIPTION(state, newValue) {
      state.currentAuction.description = newValue;
    },
    SET_NAME(state, newValue) {
      state.currentAuction.name = newValue;
    },
  },
  getters: {
    auctionName(state) {
      return state.currentAuction ? state.currentAuction.name : '';
    },

    auctionItems(state) {
      return state.currentAuction ? state.currentAuction.entries : [];
    },
    totalBids(state) {
      return state.currentAuction ? totalBids(state.currentAuction) : 0;
    },
    itemCount(state) {
      return state.currentAuction ? state.currentAuction.entries.length : 0;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch }) {},

    createAuction({ commit, state, rootState }, { id }) {
      const auction = defaultAuction_(id);
      commit('SET_CURRENT_AUCTION', auction);

      return auction;
    },
    async fetchAuction({ commit, state, rootState }, { id }) {
      const { auction: auctioninfo } = await api.get(`/api/auction/${id}`).json();

      const auction = { ...defaultAuction_, ...auctioninfo };

      // delete auction.modifiedDate;
      delete auction.versionId;
      if (!auction.descriptionHtml || auction.descriptionHtml.length === 0) {
        auction.descriptionHtml = auction.description;
      }

      auction.entries.forEach(entry => {
        if (entry.src && entry.src.indexOf('no-image') >= 0) {
          entry.src = null;
        }
      });

      commit('SET_CURRENT_AUCTION', auction);
      return auction;
    },

    saveCurrentAuction({ commit, state, rootState }) {
      // console.dir('save study');
      const auction = state.currentAuction;
      auction.versionId = uniqueId().toString();
      auction.modifiedDate = new Date();
      // eslint-disable-next-line
      const { entries, ...withoutEntries } = auction;
      http
        .fetchAndHandle('/api/auction', {
          method: 'put',
          body: JSON.stringify(withoutEntries),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        })
        .then(({ ok, data: { message } }) => {
          // if (ok) {
          //   this.dirty = false;
          // }
        })
        .catch(e => {
          console.dir(e);
          throw e;
        });
    },

    deleteCurrentAuction({ commit, state, rootState }) {
      const auction = state.currentAuction;

      const url = `/api/auction/${auction._id}`;
      return http
        .fetchAndHandle(url, {
          method: 'delete',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        })
        .then(({ ok, data }) => {
          if (ok) {
            commit('SET_CURRENT_AUCTION', false);
          }
        });
    },
  },
};

// ===
// Private helpers
// ===

function totalBids(auction) {
  if (!auction) {
    return 0;
  }

  const total = auction.entries.reduce((p, v) => {
    if (v.currentBid) {
      const n = Number(v.currentBid.startsWith('£') ? v.currentBid.slice(1) : v.currentBid);
      p += n;
    }
    return p;
  }, 0);

  return total;
}
