const footer = {
  template: `
  <footer class="main-footer">
      <div class="footer-nav--line">
      <ul class="">
        <li class="footer-nav--first"><router-link :to="{name: 'privacy-policy'}">Privacy notice</router-link></li>
        <li> &copy; 2018 Rotary Club of Kirriemuir.</li>
      </ul>
    </div>
  </footer>`,
};

export default footer;
