import ky, { HTTPError } from 'ky';

const kyi = setInstance();

const defaults = {
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*',
    },
  },
  beforeFetch: null,
};

export default kyi;

export { defaults, HTTPError };

function setInstance() {
  return ky.extend({
    hooks: {
      beforeRequest: [
        async request => {
          if (defaults.beforeFetch) {
            await defaults.beforeFetch(request);
          }
          return request;
        },
        request => {
          // merge header if available
          [...Object.entries(defaults.headers.common)].forEach(
            ([key, value]) => !request.headers.has(key) && request.headers.set(key, value),
          );

          // !request.headers.has('Authorization') && request.headers.set('Authorization', `Bearer ${accessToken}`);
          return request;
        },
      ],
    },
  });
}
