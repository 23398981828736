import loader from '../loader.js';
import store from '@state/store.js';

export const routes = [
  {
    path: '/home',
    name: 'home',
    meta: { noauth: true },
    component: () => loader.load('home/home.js'),
  },
  {
    path: '/item/:itemNo',
    name: 'detail',
    meta: { noauth: true },
    component: () => loader.load('home/item.js'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => loader.load('help/help.js'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => loader.load('about/about.js'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => loader.load('general/privacy-policy.js'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { noauth: true },
    component: () => loader.load('login/login.js'),
  },
  {
    path: '/login/sendreset',
    name: 'sendreset',
    meta: { noauth: true },
    component: () => loader.load('login/sendreset.js'),
  },
  {
    path: '/login/reset-password',
    meta: { noauth: true },
    component: () => loader.load('login/reset.js'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom });
    },
  },

  {
    path: '/auction/:id',
    name: 'auction',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('auction/auction-root.js'),
    props: true,
    children: [
      {
        path: '',
        props: true,
        meta: {},
        component: () => loader.load('auction/auction-view.js'),
        // component: StudyEditor
        children: [
          {
            path: '',
            name: 'task',
            props: true,
            meta: {
              /* perms: 'study:read'*/
            },
            component: () => loader.load('auction/auction-editor.js'),
            // component: StudyEditor
            children: [],
          },
          {
            path: 'items',
            name: 'items',
            props: true,
            meta: {
              /* perms: 'study:read'*/
            },
            component: () => loader.load('auction/auction-items.js'),
            // component: StudyEditor[]
          },
        ],
      },
      {
        path: 'item/:itemId',
        name: 'item',
        props: true,
        component: () => loader.load('auction/auction-item.js'),
      },
    ],
  },

  {
    path: '/auction',
    name: 'auctions',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('auction/auctions.js'),
  },
  {
    path: '/manage',
    component: () => loader.load('admin/admin-root.js'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'users/add',
        name: 'users/add',
        meta: {},
        component: () => loader.load('admin/add-user.js'),
        // component: StudyEditor
      },
      {
        path: 'user/:id',
        meta: {},
        component: () => loader.load('admin/user.js'),
        // component: StudyEditor
      },
      {
        path: 'users',
        name: 'users',
        meta: {
          authRequired: true,
        },
        component: () => loader.load('admin/users.js'),
        // component: StudyEditor
      },
      {
        path: 'groups',
        meta: {},
        component: () => loader.load('groups/groups.js'),
        // component: StudyEditor
      },
      {
        path: 'groups/add',
        meta: {},
        component: () => loader.load('groups/add-group.js'),
        // component: StudyEditor
      },
      {
        path: 'groups/:id/users',
        meta: {},
        component: () => loader.load('groups/group-members.js'),
      },
      {
        path: 'groups/:id/users/add',
        meta: {},
        component: () => loader.load('groups/group-add.js'),
      },
      {
        path: 'groups/:id',
        meta: {},
        component: () => loader.load('groups/group.js'),
      },
      { path: '/:remainder*', redirect: { name: 'home' } },
      { path: '/', redirect: { name: 'home' } },
    ],
  },
];
