const app = {
  render(h) {
    return h('div', { attrs: { id: 'app' } }, [
      h(
        'router-view',
        {
          attrs: { key: this.$route.fullPath },
        },
        {},
      ),
      h(
        'mdc-snackbar',
        {
          attrs: {
            snack: this.$store.state.snack,
          },
        },
        {},
      ),
    ]);
  },
  created() {
    window.addEventListener('message', this.receiveMessage, false);
  },
  methods: {
    receiveMessage(event) {
      // console.dir(event.data);
      if (event.data === 'sjs-update') {
        const data = {
          message: 'Updated. Restart for the new version.',
          actionHandler(event) {
            location.reload();
          },
          actionText: 'Restart',
          timeout: 10000,
        };
        this.$store.commit('showSnack', data);
        // this.$root.$emit('snackdata', data);
      }
    },
  },
};

export default app;
